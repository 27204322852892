.header_outer {
  display: grid;
}

.layer_1 {
  grid-column: 1;
  grid-row: 1;
}

.svg_color {
  fill: #65e9ee9e;
}

header {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.logo {
  width: 15%;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  text-align: center;
  font-size: 22px;
}

.links a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit;
}

.tepee img {
  opacity: 0;
  width: 5%;
}

.span_1:hover img {
  opacity: 1;
}

.span_2:hover img {
  opacity: 1;
}

.span_3:hover img {
  opacity: 1;
}

.span_4:hover img {
  opacity: 1;
}

.book-now-button {
  background-color: #65e9ee;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 26px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 15%;
  height: 20%;
}
@media (max-width: 800px) {
  .book-now-button {
    background-color: #65e9ee;
    color: black;
    padding: 15px 50px;

    text-decoration: none;

    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
}

.contact-now-button {
  background-color: #65e9ee;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 26px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 15%;
  height: 20%;

  @media (max-width: 800px) {
    display: none;
  }
}

.book-now-button.shake-on-hover:hover {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-5px);
  }
  20% {
    transform: translateY(5px);
  }
  30% {
    transform: translateY(-3px);
  }
  40% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(2px);
  }
  70% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .header {
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .header h1 {
    font-size: 1rem;
  }
  .links {
    display: none;
  }
}

@media (max-width: 800px) {
  .links {
    font-size: 1rem;
  }
}
