.contact-form-container {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
  }
  
  h2 {
    text-align: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  div {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input, textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    min-height: 100px;
  }
  
  button {
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #888;
    cursor: not-allowed;
  }
  
  .message {
    text-align: center;
    margin-top: 10px;
    color: green;
  }
  
  .message.error {
    color: red;
  }