.footer {
    background-color: #C3E7E5;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer img {
    padding: 20px;
 width: 30%;       
}

.footer a {
    background: #F97052;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
    border-radius: 100%;
}

@media (max-width: 480px) {
    .footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    }
    .footer a  {
        display: none;
    }

    .footer img {
     width: 80%;       
    }
}