
p {
    font-weight: 500;
    overflow-wrap: break-word;
}

h2 {
    color: #B4404F;
}

ul {
    list-style-type: circle;
}

img {
    width: 100%
}
.body_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fishing_blurb {
    text-align: center;
    font-size: 1.5rem;
    @media screen and (max-width: 700px) {
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.fishingIcon {
    width: 20px;
    @media screen and (max-width: 700px) {
        margin: 10px;    
    }
}


/* cabins */

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.grid-item {
    width: 100%;
    min-width: 0;
    display: flex;
    align-items: center;
}

.grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure images cover the entire container */
    object-position: center; /* Center the image within its container */
}

/* Responsive Layout */
@media screen and (max-width: 700px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 480px){ 
    .rentalRates {
        display: flex;
    }        
}

.rentalRates img {
        min-width: 350px;
        max-width: 350px;
}

.rentalRatesContent {
    min-width: 400px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.span1 {
padding: 0px 20px;
margin-top: -8px;
}

.span2 {
    padding: 0px 20px;
    margin-top: -40px;
    }
.h3One {
    margin-top: 40px;
}
.large_paragraph {
text-align: center;
font-size: 26px;
}

.small_paragraph {
    text-align: center;
    font-size: 18px;
}

@media (max-width: 1190px) {
    img {
        width: 100%
    }
    h2 {
        text-align: center;
    }
    .large_paragraph {
        font-size: 1.2rem;
        padding: 10px;
    }
    .small_paragraph {
        
        padding: 10px;
    }
}

@media (max-width: 480px) {
    img {
        width: 350px
    }
    h2 {
        text-align: center;
    }
    .large_paragraph {
        font-size: 1.2rem;
        padding: 10px;
    }
    .small_paragraph {
        
        padding: 10px;
    }
    .span3 {
        padding: 0px 20px;
    }
}
@media (max-width: 360px) {
    img {
        width: 100%;
        margin: 5px 20px;
    }
    h2 {
        text-align: center;
        font-size: 1rem;
    }
    .large_paragraph {
        font-size: .8rem;
        padding: 0px 20px;
    }
    .small_paragraph {
        font-size: .7rem;
        padding: 0px 20px;
    }
}


